<template>
  <div class="OptionsPage options-page">
    <div class="header">
      <div class="page-title">
        {{ pokerSession.name }}
      </div>
      <div>
        <el-button
          plain
          @click.prevent="cancel"
        >
          Cancel
        </el-button>
        <el-button
          type="success"
          title="Click to save"
          @click.prevent="saveOptions"
        >
          Save
        </el-button>
      </div>
    </div>
    <el-card class="wrapper">
      <div class="form">
        <div class="form__item">
          <label>Name</label>
          <el-input
            v-model="pokerSession.name"
            placeholder="Enter Name"
          />
        </div>
        <div class="form__item">
          <label>Description</label>
          <el-input
            v-model="pokerSession.description"
            placeholder="Enter Description"
          />
        </div>
        <div class="form__item">
          <label>
            Points limit
          </label>
          <el-input
            v-model.number="pokerSession.options.pointsLimit.points"
            placeholder="0"
          />
        </div>
        <div class="form__item">
          <el-checkbox
            v-model="pokerSession.options.pointsLimit.show"
          />
          <label>
            Show points limit for all players?
          </label>
        </div>
        <div class="form__item">
          <el-checkbox
            v-model="pokerSession.options.allowRevote"
          />
          <label>
            Allow players to change their vote after reveal?
          </label>
        </div>
        <div class="form__item">
          <el-checkbox
            v-model="pokerSession.options.showScore"
          />
          <label>
            Allow Poker Planning to calculate the score?
          </label>
        </div>
        <div class="form__item">
          <el-checkbox
            v-model="setUpTimer"
          />
          <label>
            Set up a story timer?
          </label>
        </div>
        <div
          v-if="setUpTimer"
          class="form__item"
        >
          <label>
            How much time would you like for each story?
          </label>
          <el-input
            v-model.number="pokerSession.options.timer"
            placeholder="0"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import notification from '../../../mixins/notification';
import loading from '../../../mixins/loading';

export default {
  mixins: [notification, loading],

  props: {
    pokerSession: {
      type: Object,
      require: false,
      default: () => null,
    },
  },

  data: () => ({
    setUpTimer: false,
  }),

  async created() {
    if (this.pokerSession.options.timer) {
      this.setUpTimer = true;
    }
  },

  methods: {
    cancel() {
      this.$emit('close-options');
    },
    saveOptions() {
      if (this.pokerSession.exists() && this.setUpTimer === false) {
        this.pokerSession.options.timer = 0;
        this.pokerSession.save();
      } else if (this.pokerSession.exists()) {
        this.pokerSession.save();
      } else if (!this.pokerSession.exists() && this.setUpTimer === false) {
        this.pokerSession.options.timer = 0;
      }
      this.cancel();
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/stylus"
  scoped
>
  .options-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1723px;
  }
  .wrapper {
    max-width: 1723px;
    width: 100%;
    & >>> {
      .el-card__body {
        display: flex;
        justify-content: center;
      }
    }
  }
  .form {
    width: 100%;
    max-width: 1120px;

    &__item:not(:first-child) {
      margin-top: 20px;
    }
  }
  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }
  .billing-frequency {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    text-align: left;
    grid-gap: 10px;
  }
  body {
    background-color: #efefef;
  }
</style>
